import React, { useCallback, useMemo } from "react";
import { ISessionWithBookings } from "../../interfaces/Session";
import { useBookingSession } from "../../context/BookingSession";
import { EXPERT_RACE_TIMINGS } from "../../constants/Dashboard";
import { CheckIcon } from "@heroicons/react/solid";
import { usePricechanger } from "../../context/PriceChanger";
import {
  MAIN_TRACK_PRICE_OPTIONS,
  MINI_TRACK_PRICE_OPTIONS,
  VR_PRICE_OPTIONS,
} from "../../constants/priceChanger";
import { IPriceRecommendation } from "../../interfaces";
import { isBreakOrder } from "../../utilities";
import PriceBlock from "./PriceBlock";
import QuantityBlock from "./QuantityBlock";
import { ISessionEditorLog } from "../../interfaces/types";

interface Props {
  session: ISessionWithBookings;
  recommendation?: IPriceRecommendation;
  lastQuantity: ISessionEditorLog | null
}
function PriceRow({ session, recommendation, lastQuantity }: Props) {
  const { track: view } = useBookingSession();
  const { items, toggleItem, removeItemByVariant, getBgColor } = usePricechanger();

  const sessionTime = useMemo(() => view === "MAIN_TRACK" ? session.option3?.trim()?.toLowerCase() : session.option1?.trim()?.toLowerCase(),[view, session]);
  const bgColor = useMemo(() => getBgColor(view),[view, getBgColor]);
  const price = useMemo(() => Number(session.price), [session]);
  const variantId = useMemo(() => session.id, [session]);
  const isSessionSelected = useMemo(() => !!(items.price[variantId] || items.quantity[variantId]),[items, variantId]);
  const priceOptions = useMemo(() => view === "MAIN_TRACK" ? MAIN_TRACK_PRICE_OPTIONS : (view === "MINI_TRACK" ? MINI_TRACK_PRICE_OPTIONS : VR_PRICE_OPTIONS),[view]);
  const showOpenOrCloseRace = useMemo(() => session.bookings.length === 0 || session.bookings.every(booking => isBreakOrder(booking.order)), [session]);

  const onChangeQuantity = useCallback((val: number) => {
    toggleItem({ variantId: variantId.toString(), quantity: val });
  }, [toggleItem, variantId]);

  return (
    <tr data-variant-id={variantId}>
      {/* checkbox */}
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center justify-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            {isSessionSelected ? (
              <div
                className={`rounded-md p-0.5 ${bgColor}`}
                onClick={() => {removeItemByVariant(variantId.toString())}}
              >
                <CheckIcon className={`w-4 cursor-pointer text-white`} />
              </div>
            ) : (
              <div className="border-[3px] border-solid border-gray-200 rounded-md p-1.5"></div>
            )}
          </div>
        </div>
      </td>

      {/* Session/Time */}
      <td className={`px-2 py-3 max-w-0 whitespace-nowrap`}>
        <div className="flex items-center flex-shrink-0">
          <div className="truncate hover:text-gray-600">
            <span className="font-bold text-xs leading-none text-gray-700">
              {sessionTime}
              <br />
              {view === "MAIN_TRACK" ? (
                <>
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {session.option1}
                  </span>
                  <br />
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {EXPERT_RACE_TIMINGS.includes(
                      session.option3?.trim()?.toLowerCase() || ""
                    )
                      ? "Expert"
                      : "Standard"}
                  </span>
                </>
              ) : view === "MINI_TRACK" ? (
                <>
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {session.option3}
                  </span>
                  <br />
                  <span className="font-medium text-xs leading-tight text-gray-700">
                    {EXPERT_RACE_TIMINGS.includes(
                      session.option1?.trim()?.toLowerCase() || ""
                    )
                      ? "Expert"
                      : "Standard"}
                  </span>
                </>
              ) : (
                <>
                  <small className="font-medium text-xs leading-tight text-gray-700">
                    {session.option2}
                  </small>
                </>
              )}
              <br />
            </span>
          </div>
        </div>
      </td>
      {/* Quantity */}
      <td className={`px-2 py-3 max-w-0 whitespace-nowrap`}>
        <div className="flex items-center flex-shrink-0 justify-center">
          <div className="truncate hover:text-gray-600">
            <span className="font-bold text-xs leading-none text-gray-700">
              {session.inventory_quantity}
            </span>
          </div>
        </div>
      </td>

      {/* prices */}
      <td className="px-2 py-3 max-w-0 whitespace-nowrap font-medium text-xs leading-none text-gray-700">
        <div className="flex items-center flex-shrink-0 hover:text-gray-600 gap-2">
          {priceOptions.map((p) => (
            <PriceBlock
              key={p}
              bgColor={bgColor}
              isActualPrice={price === p}
              isSelected={items.price[variantId] === p}
              onClick={() => toggleItem({ variantId: variantId.toString(), price: p })}
              price={p}
              recommendation={recommendation?.VARIANT_RECOMMENDED_PRICE === p ? recommendation : undefined}
            />
          ))}
        </div>
      </td>

      {/* open close session */}
      <td className={`px-2 py-3 max-w-0 whitespace-nowrap`}>
        <div className="flex items-center flex-shrink-0 justify-center">
          <div className="truncate hover:text-gray-600">
            <span className="font-bold text-xs leading-none text-gray-700">
              {showOpenOrCloseRace && <QuantityBlock session={session} selected={Object.keys(items.quantity).includes(variantId.toString())} onClick={onChangeQuantity} view={view} log={lastQuantity} />}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default PriceRow;
