import { useCallback } from "react";
import { IPriceRecommendation } from "../../interfaces";

interface IPriceBlockProps {
    isActualPrice: boolean;
    isSelected: boolean;
    price: number;
    bgColor: string;
    onClick: (val: number) => void;
    recommendation?: IPriceRecommendation;
}

function PriceBlock({
    isActualPrice,
    isSelected,
    price,
    bgColor,
    onClick,
    recommendation
}: IPriceBlockProps) {
    const handleClickPriceBlock = useCallback(() => {
        onClick(price);
    }, [onClick, price]);

    return (
        <div
            className={`h-full hk-gradient-bg rounded-md ${isActualPrice ? "pointer-events-none" : "cursor-pointer"} ${recommendation ? "p-1" : ""}`}
            onClick={handleClickPriceBlock}
        >
            <div className={`h-full font-bold border border-gray-300 rounded-md text-center p-3 flex justify-center items-center ${isActualPrice ? "text-gray-600 bg-gray-300" : ""} ${isSelected ? `${bgColor} text-white` : "bg-white"}`}>
                ${price}
            </div>
        </div>
    );
}

export default PriceBlock;