import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CollectionReference, collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { IPriceRecommendation, TBookingType } from "../interfaces";
import axios from "axios";
import { useSession } from "../components/Session";
import { useFirebase } from "../components/Firebase";
import { ISessionEditorLog } from "../interfaces/types";

type IItem = { variantId: string; price: number } | { variantId: string; quantity: number }
interface IItemState { price: { [key: string]: number }, quantity: { [key: string]: number } }

const defaultItems:IItemState = { price: {}, quantity: {} };


export interface IPriceChangerContext {
  isUpdating: boolean;
  view: TBookingType;
  selectedDate: Date;
  changeView: (val: TBookingType) => void;
  changeSelectedDate: (val: Date) => void;
  toggleItem: (data: IItem) => void;
  removeItemByVariant: (variantId: string, type?: "price" | "quantity") => void;
  clearItems: () => void;
  updateItems: (productId: number, cb: () => void) => void;
  recommendedPrices: IPriceRecommendation[];
  items: IItemState;
  getBgColor: (view: TBookingType) => string;
  logs: ISessionEditorLog[];
  updateLogData: (productId: number) => void;
}

const PriceChangerContext = React.createContext<IPriceChangerContext>({
  isUpdating: false,
  view: "MAIN_TRACK",
  selectedDate: new Date(),
  changeView: (val: TBookingType) => {},
  changeSelectedDate: (val: Date) => {},
  toggleItem: (data: IItem) => {},
  removeItemByVariant: () => {},
  clearItems: () => {},
  updateItems: (id: number, cb: () => void) => {},
  recommendedPrices: [],
  items: defaultItems,
  getBgColor: () => "",
  logs: [],
  updateLogData: (productId: number) => {}
});

interface Type {
  children: ReactNode;
}

const PriceChangerProvider: FC<Type> = ({ children }) => {
  const [view, setView] = useState<TBookingType>("MAIN_TRACK");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [items, setItems] = useState<IItemState>(defaultItems);
  const [isUpdating, setIsUpdating] = useState(false);
  const [recommendedPrices, setRecommendedPrices] = useState([])
  const [logs, setLogs] = useState<ISessionEditorLog[]>([])


  const { getToken } = useSession();
  const firebase = useFirebase();
  const db = useMemo(() => firebase.db, [firebase]);
  const logRef = useMemo(() => collection(db, "logs") as CollectionReference<ISessionEditorLog>,[db]);

  const toggleItem = useCallback((data: IItem) => {
    setItems((oldState) => {
      const newState = JSON.parse(JSON.stringify(oldState))
      if('price' in data){
        if(newState.price[String(data.variantId)] === data.price){
          delete newState.price[String(data.variantId)]
        }else{
          newState.price[String(data.variantId)] = data.price
        }
      }else{
        if(newState.quantity[String(data.variantId)] === data.quantity){
          delete newState.quantity[String(data.variantId)]
        }else{
          newState.quantity[String(data.variantId)] = data.quantity
        }
      }
      return newState
    });
  }, []);

  const removeItemByVariant = useCallback((variantId: string, type?: "price" | "quantity") => {
    setItems((oldState) => {
      const newState = JSON.parse(JSON.stringify(oldState));
      if(type){
        if (Object.keys(newState[type]).includes(variantId)) {
          delete newState[type][variantId];
        }
      }else{
        delete newState.price[variantId]
        delete newState.quantity[variantId]
      }
      return newState
    });
  }, []);

  const clearItems = useCallback(() => {
    setItems(defaultItems);
  }, []);

  const changeView = useCallback((val: TBookingType) => {
    setView(val);
    setItems(defaultItems)
  },[]);

  const changeSelectedDate = useCallback((val: Date) => {
    setSelectedDate(val);
    setItems(defaultItems)
  },[]);

  const getBgColor = useCallback((view: TBookingType) => view === "MAIN_TRACK" ? "bg-[#F609FF]" : (view === "MINI_TRACK" ? "bg-[#9D62FE]" : "bg-[#41BDFE]"),[])

  const updateItems = useCallback(
    async (productId: number, cb: () => void) => {
      try {
        const token = await getToken();
        if (!token) {
          return {
            status: false,
            message: "Token not generated",
          };
        }
        setIsUpdating(true);
        await axios.post(
          // "http://127.0.0.1:5001/hyper-karting/us-central1/sessionEditor",
          "https://us-central1-hyper-karting.cloudfunctions.net/sessionEditor",
          { productId, items },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsUpdating(false);
        setItems(defaultItems);
        cb();
      } catch (error) {
        setIsUpdating(false);
        return {
          status: false,
          message: error,
        };
      }
    },
    [items, getToken]
  );

  const updateLogData = useCallback(async (productId: number)=>{
    const snapshot = await getDocs(query(logRef, orderBy("ts", "desc"), where("type", "==", "SESSION_EDITOR"), where("productId", "==", productId)))
    if(!snapshot.empty && snapshot.docs.length > 0){
      setLogs(snapshot.docs.map(doc=>doc.data()))
    }
  },[logRef])

  useEffect(() => {
    axios.get("https://us-central1-hyper-karting.cloudfunctions.net/recommendedVariantsPrice?ts="+selectedDate.getTime()).then(({data})=>{
      if(data.success){
        setRecommendedPrices(data.data)
      }
    }).catch(console.log)
  }, [selectedDate])
  

  return (
    <PriceChangerContext.Provider
      value={{
        isUpdating,
        changeSelectedDate,
        changeView,
        selectedDate,
        toggleItem,
        view,
        removeItemByVariant,
        clearItems,
        updateItems,
        recommendedPrices,
        items,
        getBgColor,
        logs,
        updateLogData
      }}
    >
      {children}
    </PriceChangerContext.Provider>
  );
};

export function usePricechanger() {
  return useContext(PriceChangerContext);
}

export default PriceChangerProvider;
